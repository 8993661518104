import { getDefaultErrors, DeskingModel, Errors } from "@models";

import InputModel = DeskingModel.InputModel;
import FormErrors = Errors.FormErrors;

export function validateForm(inputDetails: InputModel) {
  let errorCount = 0;

  const newErrors: FormErrors = getDefaultErrors();

  if (inputDetails.input.vin?.length != 17) {
    newErrors.vin = "VIN must have 17 characters";
    errorCount += 1;
  }

  if (!inputDetails.input.list_price || inputDetails.input.list_price <= 0) {
    newErrors.list_price = true;
    errorCount += 1;
  }

  if (inputDetails.input.tax !== undefined && inputDetails.input.tax <= 0) {
    newErrors.tax = true;
    errorCount += 1;
  }

  if (inputDetails.input.title_fee !== undefined && inputDetails.input.title_fee <= 0) {
    newErrors.title_fee = true;
    errorCount += 1;
  }

  if (!inputDetails.input.apr || inputDetails.input.apr <= 0) {
    newErrors.apr = true;
    errorCount += 1;
  }

  if (!inputDetails.input.mileage || inputDetails.input.mileage <= 0) {
    newErrors.mileage = true;
    errorCount += 1;
  }

  if (!inputDetails.input.monthly_income || inputDetails.input.monthly_income <= 0) {
    newErrors.monthly_income = true;
    errorCount += 1;
  }

  if (inputDetails.input.credit_score == null || inputDetails.input.credit_score < 0) {
    newErrors.credit_score = true;
    errorCount += 1;
  }

  newErrors.errorCount = errorCount;

  return newErrors;
}
