export enum DataTestID {
  PAGE_NOT_FOUND_HOME_LINK = "page-not-found-home-link",
  LPE_SUBMIT = "lpe-submit",
  LPE_FORM_VIN = "lpe-form-vin",
  LPE_FORM_VIN_OPTION = "lpe-form-vin-option",
  LPE_FORM_VIN_CARET = "lpe-form-vin-caret",
  LPE_FORM_VIN_DROPDOWN = "lpe-form-vin-dropdown",
  LPE_FORM_LIST_PRICE = "lpe-form-list-price",
  LPE_FORM_DOWN_PAYMENT = "lpe-form-down-payment",
  LPE_FORM_NET_TRADE = "lpe-form-net-trade",
  LPE_FORM_TAX = "lpe-form-tax",
  LPE_FORM_TITLE_FEE = "lpe-form-title-fee",
  LPE_FORM_VSC = "lpe-form-vsc",
  LPE_FORM_GAP = "lpe-form-gap",
  LPE_FORM_DOC_FEE = "lpe-form-doc-fee",
  LPE_FORM_REG_FEE = "lpe-form-reg-fee",
  LPE_FORM_APR = "lpe-form-apr",
  LPE_FORM_COST = "lpe-form-cost",
  LPE_FORM_MILEAGE = "lpe-form-mileage",
  LPE_FORM_MONTHLY_INCOME = "lpe-form-monthly-income",
  LPE_FORM_CREDIT_SCORE = "lpe-form-credit-score",
  LPE_FORM_LINE3 = "lpe-value-line-3",
  LPE_FORM_LINE4 = "lpe-value-line-4",
  LPE_FORM_LINE5 = "lpe-value-line-5",
  LPE_FORM_SPINNER = "lpe-form-spinner",
  LPE_FORM_SUBMIT = "form-on-submit",
  LPE_AMOUNT_FINANCED = "lpe-amount-financed",
  LPE_MONTHLY_PAYMENT = "lpe-monthly-payment",
  LPE_PTI = "lpe-pti",
  LPE_TERM = "lpe-term",
  LPE_LTV = "lpe-ltv",
  LPE_POTENTIAL_LIFETIME_CASH = "lpe-plc",
  LPE_START_NEW = "lpe-start-new",
  LPE_TOOLTOP_JDPOWER = "lpe-tooltip-jdpower",
  LPE_SLIDER_CONTROL = "lpe-slider-control",
  LPE_EXPAND_MOBILE = "lpe-mobile-expand",
  LPE_SUBMIT_SAMPLE_LOAN = "lpe-submit-sample-loan",
}
