import { InputType, FormInputType } from "@enums";
import { IInputDetails, creditScoreTiers, DeskingModel } from "@models";
import { toNumberFromString, toGeneralNumberFromString } from "@utils";

export interface FormInputHandlerHandleChangeResults {
  updateRealValue: boolean;
  newRealValue: string | number | undefined;
  displayValue: string;
}

export function handleChange(
  name: FormInputType,
  value: string,
  inputDetails: DeskingModel.InputModel,
  inputMeta: IInputDetails
): FormInputHandlerHandleChangeResults {
  let initialRealValue = inputDetails.input[name] ?? "";
  let newRealValue: string | number | undefined = 0;
  let updateRealValue: boolean = true;
  let displayValue: string = "";

  if (value == undefined) value = "";

  value = value.replaceAll(",", "");
  if (value == "") {
    newRealValue = undefined;
  } else if (inputMeta.inputType == InputType.TEXT) {
    // Limit vin to 17 characters only
    if (name == FormInputType.VIN && value.length <= 17 && value.match(/^[a-zA-Z0-9]+$|^$/g)) {
      value = value.toUpperCase();
      displayValue = value;
      newRealValue = value;
    } else {
      updateRealValue = false;
      displayValue = initialRealValue.toString();
    }
  } else if (inputMeta.inputType == InputType.POS_OR_NEG) {
    let realNumber = Number(value);
    if (realNumber <= 1000000 && realNumber >= -1000000 && value.match(/^(-?\d+(\.\d*)?|\.\d+)$/)) {
      displayValue = value;
      newRealValue = toNumberFromString(value, 2);
    } else if (value == "-") {
      newRealValue = undefined;
      displayValue = "-";
    } else {
      updateRealValue = false;
      displayValue = initialRealValue.toString();
    }
  } else {
    // strip value of characters that are not numbers (inputMeta = POS_ONLY)
    let newNumberValue = Number(value);
    let isValidNumber = !Number.isNaN(newNumberValue);

    if (isValidNumber && newNumberValue <= 1000000 && newNumberValue >= 0) {
      if (name == FormInputType.MILEAGE) {
        newRealValue = newNumberValue;
        displayValue = newRealValue.toString();
      } else if (name == "apr") {
        if (newNumberValue <= 100) {
          newRealValue = toNumberFromString(value, 2);
          newRealValue = parseFloat((newRealValue / 100).toFixed(4));
          displayValue = value;
        } else {
          updateRealValue = false;
          if (typeof initialRealValue === "number") {
            displayValue = (initialRealValue * 100).toString();
          }
        }
      } else if (name == FormInputType.CREDIT_SCORE) {
        newRealValue = Number(value);
        for (const tier of creditScoreTiers) {
          if (newRealValue >= tier.min && newRealValue <= tier.max) {
            displayValue = tier.display;
            break;
          }
        }
      } else {
        newRealValue = toNumberFromString(value, 2);
        displayValue = value;
      }
    } else {
      updateRealValue = false;
      displayValue = initialRealValue.toString();
    }
  }
  return { updateRealValue, newRealValue, displayValue };
}

export function handleBlur(name: FormInputType, value: string, inputDetails: DeskingModel.InputModel): string {
  let realValue = inputDetails.input[name] ?? "";
  let displayValue: string = "";

  if (realValue === undefined || realValue === "") return displayValue;

  if (name == FormInputType.VIN) {
    displayValue = value;
  } else if (name == FormInputType.MILEAGE) {
    displayValue = toGeneralNumberFromString(toNumberFromString(realValue.toString(), 0));
  } else if (name == FormInputType.APR) {
    displayValue = `${(Number(realValue) * 100).toFixed(2)}`;
  } else {
    if (name == FormInputType.NET_TRADE && Object.is(realValue, -0)) realValue = 0;
    displayValue = Number(realValue).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return displayValue;
}

export function handleFocus(name: FormInputType, inputDetails: DeskingModel.InputModel): string {
  let realValue = inputDetails.input[name] ?? "";
  let displayValue: string = "";

  if (name == FormInputType.VIN || name == FormInputType.MILEAGE) {
    displayValue = realValue.toString();
  } else if (name == FormInputType.APR) {
    if (typeof realValue === "number") {
      const formattedValue = parseFloat((realValue * 100).toFixed(2));
      displayValue = formattedValue.toString();
    }
  } else {
    displayValue = realValue == undefined ? "" : realValue.toString();
  }

  return displayValue;
}
