import axios from "axios";
import { VehicleDetailsReq } from "../zod-schemas/vehicleDetailsSchema";
// Function to post vehicle details
export async function postVehicleDetails({ vin, state }: VehicleDetailsReq) {
  const { data } = await axios.post("/api/vehicle-details", { vin, state });
  return data;
}

// Function to get vehicle details
export async function getVehicleDetails() {
  const { data } = await axios.get("/api/vehicle-details");
  return data;
}

export async function deleteVehicleDetails(vin: string) {
  await axios.delete("/api/vehicle-details", { data: { vin } });
}

export async function reactivateVehicleDetails(vin: string) {
  await axios.put("/api/vehicle-details", { vin });
}
