import { Model, DeskingModel } from "@models";

export class ModelInputTransformer {
  public static modelInputBodyGenerator(inputDetails: DeskingModel.FormInput): DeskingModel.APIInput {
    const modelDetails = ModelInputTransformer.convertFormInputToAPIInput(inputDetails);

    // Temporary fix till we add gap_premium to the model endpoint
    modelDetails.additional_products = modelDetails.additional_products + (inputDetails.gap ?? 0);

    return modelDetails;
  }

  public static statsInputBodyGenerator(inputDetails: DeskingModel.StatsInput): DeskingModel.StatsAPIInput {
    const modelDetails = ModelInputTransformer.convertFormInputToAPIInput(inputDetails);

    return {
      ...modelDetails,
      monthly_payment: inputDetails.monthly_payment,
      term: inputDetails.term,
      zip: inputDetails.zip,
      gap_premium: inputDetails.gap ?? 0,
    };
  }

  static convertFormInputToAPIInput(inputDetails: Model.FormInput): Model.APIInput {
    return {
      account_uuid: inputDetails.account_uuid,
      vin: inputDetails.vin,
      list_price: inputDetails.list_price ?? 0,
      down_payment: (inputDetails.down_payment ?? 0) + (inputDetails.net_trade ?? 0),
      tax: inputDetails.tax ?? 0,
      title_fee: inputDetails.title_fee ?? 0,
      registration_fee: (inputDetails.registration_fee ?? 0) + (inputDetails.document_fee ?? 0),
      additional_products: inputDetails.vsc ?? 0,
      apr: inputDetails.apr ?? 0,
      mileage: inputDetails.mileage ?? 0,
      vehicle_cost: inputDetails.vehicle_cost ?? 0,
      monthly_income: inputDetails.monthly_income ?? 0,
      state: inputDetails.state,
      credit_score: inputDetails.credit_score ?? 0,
    };
  }
}
