export enum APIReturnCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  API_RATE_LIMIT = 429,
  INTERNAL_SERVER_ERROR = 500,
}

export const APIErrorCodes = new Set([
  APIReturnCode.BAD_REQUEST,
  APIReturnCode.FORBIDDEN,
  APIReturnCode.NOT_FOUND,
  APIReturnCode.CONFLICT,
  APIReturnCode.INTERNAL_SERVER_ERROR,
]);
