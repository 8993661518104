import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "@components/Sonner";
import axios, { AxiosError } from "axios";
import { NewUserRequest } from "@db/models/DbModel";

const registerUser = async (usrModel: NewUserRequest) => {
  try {
    const response = await axios.post("/api/pre-register", usrModel, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

const handleError = (error: AxiosError) => {
  Sentry.captureException(error);
  if (error.response) {
    switch (error.response.status) {
      case 400:
        toast.error("This email is already registered. Please try again with a different email.");
        break;
      case 500:
      case 404:
      default:
        toast.error("An unexpected error occurred. Please try again later.");
        break;
    }
  } else {
    toast.error("An unexpected error occurred. Please try again later.");
  }
};

const handleSuccess = () => {
  toast.success("Registration successful!");
};

export const useFormSubmission = () => {
  const mutation = useMutation({
    mutationFn: registerUser,
    onSuccess: () => {
      handleSuccess();
    },
    onError: (error: AxiosError) => {
      handleError(error);
    },
  });

  return mutation;
};
