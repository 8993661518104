export enum PathName {
  BORROWING_BASE = "/portfolio-intelligence/borrowing-base",
  PERFORMANCE_REVIEW = "/portfolio-intelligence/performance-review",
  KEY_PORTFOLIO_INDICATORS = "/portfolio-intelligence/key-portfolio-indicators",
  MY_DEALS = "/portfolio-intelligence/my-deals",
  MY_CASH_FLOW_PLAN = "/agora-insights/my-cash-flow-plan",
  SUPPORT_LIBRARY = "/support-library",
  LPE = "/loan-performance-engine",
  HOME = "/",
  AI_TRAINER = "/ai-trainer",
  DEVELOPER_WALL = "/developer-wall",
  USER_MANAGEMENT = "/user-management",
  CORE_DB_DATA_MANAGER = "/core-db-data-manager",
}
