// enum of all us states
export enum States {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export const StateAbbr = {
  [States.AL]: "AL",
  [States.AK]: "AK",
  [States.AZ]: "AZ",
  [States.AR]: "AR",
  [States.CA]: "CA",
  [States.CO]: "CO",
  [States.CT]: "CT",
  [States.DE]: "DE",
  [States.FL]: "FL",
  [States.GA]: "GA",
  [States.HI]: "HI",
  [States.ID]: "ID",
  [States.IL]: "IL",
  [States.IN]: "IN",
  [States.IA]: "IA",
  [States.KS]: "KS",
  [States.KY]: "KY",
  [States.LA]: "LA",
  [States.ME]: "ME",
  [States.MD]: "MD",
  [States.MA]: "MA",
  [States.MI]: "MI",
  [States.MN]: "MN",
  [States.MS]: "MS",
  [States.MO]: "MO",
  [States.MT]: "MT",
  [States.NE]: "NE",
  [States.NV]: "NV",
  [States.NH]: "NH",
  [States.NJ]: "NJ",
  [States.NM]: "NM",
  [States.NY]: "NY",
  [States.NC]: "NC",
  [States.ND]: "ND",
  [States.OH]: "OH",
  [States.OK]: "OK",
  [States.OR]: "OR",
  [States.PA]: "PA",
  [States.RI]: "RI",
  [States.SC]: "SC",
  [States.SD]: "SD",
  [States.TN]: "TN",
  [States.TX]: "TX",
  [States.UT]: "UT",
  [States.VT]: "VT",
  [States.VA]: "VA",
  [States.WA]: "WA",
  [States.WV]: "WV",
  [States.WI]: "WI",
  [States.WY]: "WY",
};
