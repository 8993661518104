import { useEffect, RefObject } from "react";

export const useClickOutside = (refs: RefObject<HTMLElement>[], isOpen: boolean, onClose: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutside = refs.every((ref) => ref.current && !ref.current.contains(event.target as Node));

      if (isOutside) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, refs, onClose]);
};
