import axios from "axios";
import { NextRequest, NextResponse } from "next/server";

import { APIReturnCode } from "@/ctx-api/enums/APIReturnCode";
import { DocIntelligenceResponseStatus } from "@enums";

export async function getDocumentIntelligenceAnalysis(req: NextRequest): Promise<NextResponse> {
  const formData = await req.formData();
  const image = formData.get("image") as File;
  const arrayBuffer = await image.arrayBuffer();
  const fileBuffer = Buffer.from(arrayBuffer);
  const base64Source = fileBuffer.toString("base64");
  const key = process.env.AZURE_FORM_RECOGNIZER_API_KEY;
  const endpoint = process.env.AZURE_FORM_RECOGNIZER_API_URL;
  const body = { base64Source };
  const queryString =
    "/documentintelligence/documentModels/prebuilt-invoice:analyze?api-version=2024-07-31-preview&features=queryFields&queryFields=Vin,SellingPrice,DownPayment,NetTrade,Tax,TitleFee,Vsc,Gap,Doc,Reg,Apr,VehicleCost,Mileage,GrossMonthlyIncome";
  const AzureQueryAPIClient = axios.create({
    baseURL: endpoint,
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": key,
    },
  });

  const response = await AzureQueryAPIClient.post(`${queryString}`, body);
  const operationLocationUrl = await response.headers["operation-location"].split("com")[1];

  const tryCountMax = 4;
  let tryCount = 0;
  let result: any = "";

  while (tryCount < tryCountMax) {
    await waitThreeSeconds();

    const response = await AzureQueryAPIClient.get(operationLocationUrl);
    result = await response.data;

    if (result?.status == DocIntelligenceResponseStatus.SUCCEEDED) {
      break;
    }

    tryCount = tryCount + 1;
  }

  if (result?.status !== DocIntelligenceResponseStatus.SUCCEEDED) {
    throw new Error(`Unable to retrieve analysis result: Result status ${result.status} after ${tryCountMax} counts`);
  }

  return new NextResponse(JSON.stringify(result), { status: APIReturnCode.OK });
}

async function waitThreeSeconds() {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 3000);
  });
}
