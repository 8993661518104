import * as Sentry from "@sentry/nextjs";

import { APIResponseType } from "../enums/APIResponseType";
import { APIErrorCodes } from "../enums/APIReturnCode";

// Making a call to any API
export async function callAPI(
  apiPath: string,
  requestOptions: any = {},
  responseType: APIResponseType = APIResponseType.JSON,
  propagateError: boolean = false
): Promise<any> {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const timeoutValue = process.env.NEXT_PUBLIC_API_TIMEOUT_IN_SECONDS;
  const timeOutDurationInSeconds = timeoutValue ? parseInt(timeoutValue) : 40;
  const timeOutDuration: number = timeOutDurationInSeconds * 1000;

  /* istanbul ignore next */
  const timeout = setTimeout(() => {
    abortController.abort();
  }, timeOutDuration);

  let status = 0;
  let data = {};

  return fetch(apiPath, { ...requestOptions, signal })
    .then(async (response) => {
      status = response.status;
      if (responseType == APIResponseType.JSON) {
        data = await response?.json();
      } else if (responseType == APIResponseType.TEXT) {
        data = await response.text();
      } else if (responseType == APIResponseType.BLOB) {
        data = await response.blob();
      }

      if (APIErrorCodes.has(status)) throw new Error(JSON.stringify(data));

      return { response, status, data };
    })
    .catch((error) => {
      if (error.name === "AbortError") {
        status = 408;
        data = { error: "Request timeout" };
      } else if (error.status != null) {
        status = error.status;
        data = { error: error.message };
      } else {
        data = { error: error };
      }

      const errorText =
        "An error has occurred in an api call to: " +
        apiPath +
        " status: " +
        status +
        " message: " +
        JSON.stringify(data);

      if (propagateError) throw new Error(error.message);
      else Sentry.captureException(errorText);

      return { response: null, status: status, data: data, error: error };
    })
    .finally(() => {
      clearTimeout(timeout);
      abortController.abort();
    });
}
