import * as Sentry from "@sentry/react";
import { MutationFunction, useMutation } from "@tanstack/react-query";
import { toast } from "@components/Sonner";
import axios, { AxiosError } from "axios";

const updateRole: MutationFunction<any, [string, string]> = async ([userId, roleId]) => {
  if (!userId || !roleId) {
    throw new Error("Missing required parameters at hook");
  }

  try {
    const response = await axios.patch(
      "/api/update-user-role",
      { userId, roleId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Sentry.captureException(error);
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

const handleError = (error: AxiosError) => {
  Sentry.captureException(error);
  toast.error("Failed to update user role");
};

export const useUpdateUserRole = () => {
  return useMutation({
    mutationFn: updateRole,
    onError: handleError,
  });
};
