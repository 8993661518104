import { Model } from "@models";

export const formMocker: Model.FormInput = {
  account_uuid: "",
  vin: "4T1BF1FK8CU168922",
  mileage: 68000,
  state: "",
  monthly_income: 4000,
  apr: 0.21,
  list_price: 10000,
  vsc: 100,
  gap: 100,
  tax: 100,
  title_fee: 100,
  document_fee: 100,
  registration_fee: 100,
  down_payment: 2000,
  net_trade: 0,
  vehicle_cost: 10000,
  credit_score: 490,
  term: 32,
};
