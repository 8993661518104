export enum FormInputType {
  VIN = "vin",
  LIST_PRICE = "list_price",
  DOWN_PAYMENT = "down_payment",
  NET_TRADE = "net_trade",
  TAX = "tax",
  TITLE_FEE = "title_fee",
  VSC = "vsc",
  GAP = "gap",
  DOCUMENT_FEE = "document_fee",
  REGISTRATION_FEE = "registration_fee",
  APR = "apr",
  VEHICLE_COST = "vehicle_cost",
  MILEAGE = "mileage",
  MONTHLY_INCOME = "monthly_income",
  CREDIT_SCORE = "credit_score",
  APPLICANT_ID = "applicationId",
}
