import { z } from "zod";
import { ReturnValue, FormatType } from "@enums";

export const vinQueryEnvsSchema = z.object({
  VINQUERY_API_URL: z.string(),
  VINQUERY_API_ACCESS_CODE: z.string(),
});

export const VinOcrParamsSchema = z.object({
  accesscode: z.string(),
  saveimage: z.enum([ReturnValue.TRUE, ReturnValue.FALSE]).default(ReturnValue.FALSE),
  vindecode: z.enum([ReturnValue.TRUE, ReturnValue.FALSE]).default(ReturnValue.TRUE),
  format: z.enum([FormatType.JSON, FormatType.XML]).default(FormatType.JSON),
});

export type VinOcrParamsType = z.infer<typeof VinOcrParamsSchema>;

export const VinOcrResponseSchema = z.object({
  status: z.string(),
  vin_captured: z.string(),
  vindecode: z
    .object({
      status: z.string(),
      make: z.string(),
      model: z.string(),
      year: z.number(),
    })
    .nullable(),
});

export const VinOcrErrorResponseSchema = z.object({
  service: z.string(),
  version: z.string(),
  date: z.string(),
  status: z.string(),
  message_key: z.number(),
  message: z.string(),
  name: z.string().optional(),
});

export type VinOcrResponseType = z.infer<typeof VinOcrResponseSchema>;
export type VinOcrErrorResponseType = z.infer<typeof VinOcrErrorResponseSchema>;
