export function toPercentageFromNumber(num: number, dec: number = 0): string {
  const percentage = num * 100;
  return percentage % 1 === 0 ? `${percentage.toFixed(0)}%` : `${percentage.toFixed(dec)}%`;
}

export function toNumberFromString(value: string | number, dec: number = 0): number {
  const parsedValue = parseFloat(value.toString());

  if (isNaN(parsedValue)) {
    return 0;
  }

  return parseFloat(parsedValue.toFixed(dec));
}

/**
 * Converts a currency-formatted string to a number.
 * @param value The currency-formatted string to convert.
 * @returns The numeric value.
 */
export function toNumberFromCurrency(value: string): number {
  const cleanedValue = value.replace(/[$,]/g, ""); // Remove dollar signs and commas
  const parsedValue = parseFloat(cleanedValue); // Parse the number value

  return parsedValue;
}

/**
 * Formats a string as currency with commas and a dollar sign.
 * @param value The string value to format.
 * @returns The currency-formatted string.
 */
export function toCurrencyFromString(value: string): string {
  // Check if the value is undefined or null
  if (value === undefined || value === null) {
    return "";
  }

  // Remove any unexpected characters and convert to a number
  const numericValue = value.toString().replace(/[^0-9.-]+/g, "");

  // Parse the input as a number.
  const parsedValue = parseFloat(numericValue);

  // Check if the parsed value is a valid number.
  if (isNaN(parsedValue)) {
    return "";
  }

  // Format the number as currency with commas and a dollar sign.
  return parsedValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

/**
 * Formats a number as a currency string with a specified number of decimal places.
 *
 * @param amount - The number to format as a currency string.
 * @param decimalPlaces - The number of decimal places to display (default is 2).
 * @param currencySymbol - The currency symbol to display (default is '$').
 *
 * @returns The currency-formatted string.
 */
export function toCurrencyFromNumber(amount: number, decimalPlaces = 2, currencySymbol = "$"): string {
  if (amount == null || amount === undefined) {
    return "";
  }

  const formatted = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  // Replace the default currency symbol with the specified one
  return formatted.replace(/\$/, currencySymbol);
}

/**
 * Converts a number to a string with thousands separators.
 * @param {number} number The number to convert.
 * @returns {string} The string with thousands separators.
 */
export function toGeneralNumberFromString(number?: number, useDecimals: boolean = true): string {
  if (number == null || number === undefined) {
    return "";
  }

  let numberString = number.toString();

  if (!useDecimals) {
    numberString = Math.round(number).toString();
  }

  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Converts a string to a date in the format MM/DD/YYYY.
 * @param {string} date The date string to convert.
 * @param {string} format The format of the date string.
 * @returns {string} The date in the format.
 * @throws {Error} If the date string is not in the format YYYY-MM-DD.
 */
export function toDateFromString(newDate: string | Date, format?: string): string {
  if (
    newDate == null ||
    newDate === "" ||
    newDate === undefined ||
    format == null ||
    format === "" ||
    format === undefined
  ) {
    return "";
  }

  const date = new Date(newDate);

  const year = date.getFullYear();
  let month: number | string = date.getUTCMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day: number | string = date.getUTCDate();
  day = day < 10 ? `0${day}` : day;

  switch (format) {
    case "MM/DD/YYYY":
      return `${month}/${day}/${year}`;
    case "DD/MM/YYYY":
      return `${day}/${month}/${year}`;
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    default:
      return date.toString();
  }
}

/**
 * Formats a phone number as (XXX) XXX-XXXX.
 * @param {string} phoneNumber The number to be formatted.
 * @returns {string} The formatted number.
 */
export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber == null || phoneNumber === undefined) {
    return "";
  }
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

/**
 * Capitalizes the first letter of each word in a string.
 * @param {string} str The string to capitalize.
 * @returns {string} The capitalized string.
 */
export function toProperString(str: string): string {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    })
    .join(" ");
}

export function stringToShortDate(date: string): string {
  if (date == null || date === undefined) {
    return "";
  }

  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function stringToLongDateWithTime(date: string): string {
  if (date == null || date === undefined) {
    return "";
  }

  const dateObj = new Date(date);

  const month = dateObj.toLocaleString("default", { month: "long" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const hours = dateObj.getHours() % 12 || 12; // Convierte a formato 12 horas
  const minutes = dateObj.getMinutes().toString().padStart(2, "0"); // Asegura dos dígitos
  const ampm = dateObj.getHours() >= 12 ? "PM" : "AM";

  return `${month} ${day}, ${year} at ${hours}:${minutes} ${ampm}`;
}
