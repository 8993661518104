import { addMinutes, differenceInMinutes, format } from "date-fns";

import { CaptureRateLimit } from "@/ctx-auth0/utils/SessionUtil";
import { LimitReachedError } from "@models";

const VIN_RATE_LIMIT_DURATION_MINUTES = Number(process.env.NEXT_PUBLIC_VIN_CAPTURE_RATE_DURATION_IN_MINUTES);
const VIN_MAX_REQUESTS_PER_PERIOD = Number(process.env.NEXT_PUBLIC_VIN_CAPTURE_RATE_LIMIT);
const FORM_RATE_LIMIT_DURATION_MINUTES = Number(process.env.NEXT_PUBLIC_FORM_CAPTURE_RATE_DURATION_IN_MINUTES);
const FORM_MAX_REQUESTS_PER_PERIOD = Number(process.env.NEXT_PUBLIC_FORM_CAPTURE_RATE_LIMIT);

export function isFormRateLimitReached(limitDetails: CaptureRateLimit) {
  return isRateLimitReached(limitDetails, FORM_RATE_LIMIT_DURATION_MINUTES, FORM_MAX_REQUESTS_PER_PERIOD);
}

export function isVinOCRRateLimitReached(limitDetails: CaptureRateLimit) {
  return isRateLimitReached(limitDetails, VIN_RATE_LIMIT_DURATION_MINUTES, VIN_MAX_REQUESTS_PER_PERIOD);
}

function isRateLimitReached(limitDetails: CaptureRateLimit, durationInMinutes: number, maxRequests: number): boolean {
  const currentTime = new Date();
  const limitResetTime = addMinutes(new Date(limitDetails.rateLimitDate), durationInMinutes);

  return limitDetails.rateLimitCount >= maxRequests && currentTime < limitResetTime;
}

export function getFormLimitMessage(limitDetails: CaptureRateLimit): LimitReachedError {
  return getLimitMessage(limitDetails, FORM_RATE_LIMIT_DURATION_MINUTES, FORM_MAX_REQUESTS_PER_PERIOD);
}

export function getVinOCRLimitMessage(limitDetails: CaptureRateLimit): LimitReachedError {
  return getLimitMessage(limitDetails, FORM_RATE_LIMIT_DURATION_MINUTES, FORM_MAX_REQUESTS_PER_PERIOD);
}

function getLimitMessage(
  limitDetails: CaptureRateLimit,
  durationInMinutes: number,
  maxRequests: number
): LimitReachedError {
  const limitResetTime = addMinutes(new Date(limitDetails.rateLimitDate), durationInMinutes);
  const timeUntilReset = differenceInMinutes(limitResetTime, new Date()); // Does not account for seconds
  const formattedResetTime = format(limitResetTime, "h:mm a");
  const timeMessage = timeUntilReset <= 0 ? "less than a minute" : `${timeUntilReset} minute(s)`;
  const message = `Rate limit reached. Please wait until the rate limit resets in ${timeMessage}.`;

  const metadata = {
    maxRequestsPerPeriod: maxRequests,
    periodDurationMinutes: durationInMinutes,
    resetTime: formattedResetTime,
    timeUntilResetMinutes: timeUntilReset,
  };

  return { message, name: "LimitReachedError", metadata };
}

export function createUpdatedFormLimitObject(limitDetails?: CaptureRateLimit): CaptureRateLimit {
  return createUpdatedLimitObject(FORM_RATE_LIMIT_DURATION_MINUTES, limitDetails);
}

export function createUpdatedVinOCRLimitObject(limitDetails?: CaptureRateLimit): CaptureRateLimit {
  return createUpdatedLimitObject(VIN_RATE_LIMIT_DURATION_MINUTES, limitDetails);
}

export function createUpdatedLimitObject(durationInMinutes: number, limitDetails?: CaptureRateLimit): CaptureRateLimit {
  const currentTime = new Date();

  if (!limitDetails) {
    return {
      rateLimitCount: 1,
      rateLimitDate: currentTime,
    };
  }

  const { rateLimitCount, rateLimitDate } = limitDetails;
  const limitResetTime = addMinutes(new Date(rateLimitDate), durationInMinutes);
  const isReset = currentTime >= limitResetTime;

  return {
    rateLimitCount: isReset ? 1 : rateLimitCount + 1,
    rateLimitDate: isReset ? currentTime : rateLimitDate,
  };
}
