export interface CreditScoreTier {
  name: string;
  display: string;
  min: number;
  max: number;
}

export const creditScoreTiers: CreditScoreTier[] = [
  {
    name: "Tier1",
    display: "550+",
    min: 550,
    max: 850,
  },
  {
    name: "Tier2",
    display: "500 - 549",
    min: 500,
    max: 549,
  },
  {
    name: "Tier3",
    display: "1 - 499",
    min: 1,
    max: 499,
  },
  {
    name: "Tier4",
    display: "No FICO/ITIN",
    min: 0,
    max: 0,
  },
];
