export enum ApplicationStatuses {
  AC = "Auto Conditioned",
  RS = "Rescan Sent",
  EV = "Econ Validation",
  BH = "Book/Dont Fund",
  RL = "Return to Lender",
  DR = "Draft Received",
  CAN = "Cancelled",
  RU = "Return to UW",
  X = "Expired",
  NB = "Not Booked",
  RD = "Recommend Decline",
  RA = "Recommend Approve",
  CB_ERR = "Credit Bureau Error",
  ERR = "System Error",
  I = "Not Received",
  RN = "Returned",
  FR = "Funding Manager Review",
  UR = "Underwriter Review",
  VI = "Verifications Incomplete",
  B = "Booked",
  RB = "Ready to Book",
  CR = "Contract Received",
  D = "Declined",
  C = "Conditional Approval",
  A = "Approved",
  MR = "Recommend Review",
  AD = "Auto Declined",
  AA = "Auto Approved",
  IP = "Initial Processing",
  AR = "Application Received",
}

export const RehashUpdatableStatuses = ["A", "AA", "AC", "C", "MR"];
