import { DataTestID, InputSymbolType, InputType, FormInputType } from "@enums";

export interface IInputDetails {
  inputName: FormInputType;
  inputDisplayName: string;
  inputPlaceHolder: string;
  inputSymbolType: InputSymbolType;
  errorMessage: string;
  isRequired: boolean;
  inputType: InputType;
  dataTestId?: DataTestID;
  disclaimerText?: string;
}

export const vinDetails: IInputDetails = {
  inputName: FormInputType.VIN,
  inputDisplayName: "VIN Number",
  inputPlaceHolder: "Enter VIN",
  inputSymbolType: InputSymbolType.NONE,
  errorMessage: "VIN is required",
  isRequired: true,
  inputType: InputType.TEXT,
  dataTestId: DataTestID.LPE_FORM_VIN,
};

export const applicationInputDetails: IInputDetails = {
  inputName: FormInputType.APPLICANT_ID,
  inputDisplayName: "App Number",
  inputPlaceHolder: "Application ID",
  inputSymbolType: InputSymbolType.NONE,
  errorMessage: "",
  isRequired: false,
  inputType: InputType.TEXT,
};

export const sellingPriceDetails: IInputDetails = {
  inputName: FormInputType.LIST_PRICE,
  inputDisplayName: "SELLING PRICE",
  inputPlaceHolder: "Selling Price",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Selling Price is required",
  isRequired: true,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_LIST_PRICE,
};

export const downPaymentDetails: IInputDetails = {
  inputName: FormInputType.DOWN_PAYMENT,
  inputDisplayName: "DOWN PAYMENT",
  inputPlaceHolder: "Cash",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Down Payment is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_DOWN_PAYMENT,
};

export const netTradeDetails: IInputDetails = {
  inputName: FormInputType.NET_TRADE,
  inputDisplayName: "NET TRADE",
  inputPlaceHolder: "Net Trade",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Net trade is required",
  isRequired: false,
  inputType: InputType.POS_OR_NEG,
  dataTestId: DataTestID.LPE_FORM_NET_TRADE,
};

export const taxDetails: IInputDetails = {
  inputName: FormInputType.TAX,
  inputDisplayName: "TAX",
  inputPlaceHolder: "Tax",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Tax is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_TAX,
};

export const titleFeeDetails: IInputDetails = {
  inputName: FormInputType.TITLE_FEE,
  inputDisplayName: "TITLE FEE",
  inputPlaceHolder: "Title Fee",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Title Fee is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_TITLE_FEE,
};

export const vscDetails: IInputDetails = {
  inputName: FormInputType.VSC,
  inputDisplayName: "VSC",
  inputPlaceHolder: "VSC",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "VSC is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_VSC,
};

export const gapDetails: IInputDetails = {
  inputName: FormInputType.GAP,
  inputDisplayName: "GAP",
  inputPlaceHolder: "Gap",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Gap is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_GAP,
};

export const documentFeeDetails: IInputDetails = {
  inputName: FormInputType.DOCUMENT_FEE,
  inputDisplayName: "DOC",
  inputPlaceHolder: "Document Fee",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Document Fee is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_DOC_FEE,
};

export const registrationDetails: IInputDetails = {
  inputName: FormInputType.REGISTRATION_FEE,
  inputDisplayName: "REG",
  inputPlaceHolder: "Registration Fee",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Registration Fee is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_REG_FEE,
};

export const aprDetails: IInputDetails = {
  inputName: FormInputType.APR,
  inputDisplayName: "APR",
  inputPlaceHolder: "APR",
  inputSymbolType: InputSymbolType.PERCENT,
  errorMessage: "APR is required",
  isRequired: true,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_APR,
  disclaimerText: "Follow Local Usury Guidelines",
};

export const vehicleCostDetails: IInputDetails = {
  inputName: FormInputType.VEHICLE_COST,
  inputDisplayName: "VEHICLE COST",
  inputPlaceHolder: "Dealer Cost + Reconditioning",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Vehicle Cost is required",
  isRequired: false,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_COST,
};

export const mileageDetails: IInputDetails = {
  inputName: FormInputType.MILEAGE,
  inputDisplayName: "MILEAGE",
  inputPlaceHolder: "Mileage",
  inputSymbolType: InputSymbolType.VEHICLE,
  errorMessage: "Mileage is required",
  isRequired: true,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_MILEAGE,
};

export const grossMonthlyIncomeDetails: IInputDetails = {
  inputName: FormInputType.MONTHLY_INCOME,
  inputDisplayName: "GROSS MONTHLY INCOME",
  inputPlaceHolder: "Customer's Gross Monthly Income",
  inputSymbolType: InputSymbolType.DOLLAR,
  errorMessage: "Gross Monthly Income is required",
  isRequired: true,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_MONTHLY_INCOME,
};

export const creditScoreDetails: IInputDetails = {
  inputName: FormInputType.CREDIT_SCORE,
  inputDisplayName: "CREDIT SCORE",
  inputPlaceHolder: "Customer's Credit Score",
  inputSymbolType: InputSymbolType.GAUGE,
  errorMessage: "Credit Score is required",
  isRequired: true,
  inputType: InputType.POS_ONLY,
  dataTestId: DataTestID.LPE_FORM_CREDIT_SCORE,
};
