import {
  ORION_DESKING_MODEL,
  ORION_DESKING_MODEL_STATS,
  ORION_DESKING_MODEL_STIPULATIONS,
  ORION_VINS,
  ORION_BULK_VEHICLE_DETAILS,
  ORION_VEHICLE_IMAGE,
  ORION_COLLATERAL_VALUATION,
} from "@/ctx-api/constants/API";
import { APIMethod } from "@/ctx-api/enums/APIMethod";
import { callServerSideAPI } from "@/ctx-api/utils/ClientSideAPICaller";
import { HookResponse, Model, DeskingModel } from "@models";
import { ModelInputTransformer } from "@utils";
import { BulkVehicleDetailsReq } from "@/zod-schemas/bulk-vehicle-details-schema";
import { toast } from "@components/Sonner";

export function useOrionDataGetter() {
  const makeAPICall = async (apiPath: string, apiName: string, requestOption: any = null) => {
    const response = await callServerSideAPI(apiPath, requestOption);

    if (response.status == 408) {
      toast.error("Request Timeout");
    } else if (response.status == 500) {
      toast.error("Orion Error Code 500");
    } else if (response.status != 200) {
      console.log(
        "Error getting the " + apiName + ". Status: " + response.status + " details: " + JSON.stringify(response.data)
      );
    }

    return { data: response.data, status: response.status };
  };

  const getVinsForAccount = async () => {
    const requestOptions = {
      method: APIMethod.POST,
      headers: { "Content-Type": "application/json" },
    };

    return makeAPICall(ORION_VINS, "vinList", requestOptions);
  };

  const getDeskingModel = async (modelInput: DeskingModel.FormInput) => {
    const modelInputTransformed: DeskingModel.APIInput = ModelInputTransformer.modelInputBodyGenerator(modelInput);

    const requestOptions = {
      method: APIMethod.POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(modelInputTransformed),
    };

    const response: HookResponse = await makeAPICall(ORION_DESKING_MODEL, "deskingModel", requestOptions);

    if (response.status == 501) toast.error("Model Error Code 501");

    return response;
  };

  const getDeskingModelStats = async (statsInput: DeskingModel.StatsInput) => {
    const statsInputTransformed: DeskingModel.APIInput = ModelInputTransformer.statsInputBodyGenerator(statsInput);
    const requestOptions = {
      method: APIMethod.POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(statsInputTransformed),
    };

    const response: HookResponse = await makeAPICall(ORION_DESKING_MODEL_STATS, "deskingStats", requestOptions);

    //if (response.status == 501) toast.error("Stats Error Code 501");
    //else if (response.status == 422) toast.error("Stats Error Code 422");

    return response;
  };

  const getDeskingModelStipulations = async () => {
    const requestOptions = {
      method: APIMethod.GET,
    };

    const response: HookResponse = await makeAPICall(
      ORION_DESKING_MODEL_STIPULATIONS,
      "deskingStipulations",
      requestOptions
    );

    //if (response.status == 501) toast.error("Stipulations Error Code 501");

    return response;
  };

  const getBulkVehicleDetails = async (vinInput: BulkVehicleDetailsReq) => {
    const requestOptions = {
      method: APIMethod.POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(vinInput),
    };
    return makeAPICall(ORION_BULK_VEHICLE_DETAILS, "bulkVehicleDetails", requestOptions);
  };

  const getVinImage = async (imageVinInput: Model.VehicleImage) => {
    const url = `${ORION_VEHICLE_IMAGE}/${imageVinInput.vin}/${imageVinInput.size}`;
    console.log("getVinImage Request: ", url);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    return makeAPICall(url, "vehicleImage", requestOptions);
  };

  const getCollateralValuation = async (collateralValuation: Model.CollateralValuation) => {
    const requestOptions = {
      method: APIMethod.POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(collateralValuation),
    };
    return makeAPICall(ORION_COLLATERAL_VALUATION, "collateralValuation", requestOptions);
  };

  return {
    getVinsForAccount,
    getDeskingModel,
    getDeskingModelStats,
    getDeskingModelStipulations,
    getBulkVehicleDetails,
    getVinImage,
    getCollateralValuation,
  };
}
