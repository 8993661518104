import { VinOcrErrorResponseType } from "../zod-schemas/vinQuerySchema";

export class LimitReachedError extends Error {
  name: string;
  metadata: {
    maxRequestsPerPeriod: number;
    periodDurationMinutes: number;
    resetTime: string;
    timeUntilResetMinutes: number;
  };

  constructor(
    message: string,
    metadata: {
      maxRequestsPerPeriod: number;
      periodDurationMinutes: number;
      resetTime: string;
      timeUntilResetMinutes: number;
    }
  ) {
    super(message);
    this.name = "LimitReachedError";
    this.metadata = metadata;
  }
}

export class VinOcrErrorResponse extends Error {
  name: string;
  metadata: VinOcrErrorResponseType;

  constructor(
    message: string,
    metadata: { service: string; version: string; date: string; status: string; message_key: number; message: string }
  ) {
    super(message);
    this.name = "VinOcrErrorResponse";
    this.metadata = metadata;
  }
}
