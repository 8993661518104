import * as Sentry from "@sentry/react";
import { MutationFunction, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

const updateUsername: MutationFunction<any, [string, string]> = async ([userId, newName]) => {
  const body = { userId, newName };

  if (!userId || !newName) {
    throw new Error("Missing required parameters at hook");
  }
  try {
    const response = await axios.patch("/api/update-user-name", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

const handleError = (error: AxiosError) => {
  Sentry.captureException(error);
};

export const useUpdateUsername = () => {
  const mutation = useMutation({
    mutationFn: updateUsername,
    onError: handleError,
  });

  return mutation;
};
