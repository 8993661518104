// // Google search
// Social Media
// Referral
// Email
// Partner Website
// Other (edited)
export enum DiscoverySource {
  GoogleSearch = "Google Search",
  SocialMedia = "Social Media",
  Referral = "Referral",
  Email = "Email",
  PartnerWebsite = "Partner Website",
  Other = "Other",
}
