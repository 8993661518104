export enum RuleType {
  DOWN_PAYMENT = "DownPaymentSuggested",
  VSC = "ServiceContracts",
  GAP = "GapPremium",
  APR = "APR",
  MILEAGE = "Mileage",
  AGE_OF_VEHICLE = "AgeOfVehicle",
  PAYMENT_TO_INCOME = "PaymentToIncome",
  TERM = "Term",
  LTV = "LTV",
  CREDIT_SCORE = "CreditScore",
  NONE = "NONE",
}

export function LtvRules(): Array<RuleType> {
  return [RuleType.LTV, RuleType.CREDIT_SCORE];
}
