import { AdvanceRate, DecisionResult, RuleType } from "@enums";
import { IDecisionRules } from "./DecisionRules";
import { Errors } from "./Errors";
import { Model } from "./Model";
import { IProgram } from "./Program";
import { Stats } from "./Stats";

export class DefaultInput {
  static get values(): Model.FormInput {
    return {
      account_uuid: "",
      applicationId: "",
      vin: "",
      mileage: undefined,
      state: null,
      monthly_income: undefined,
      apr: undefined,
      list_price: undefined,
      vsc: undefined,
      gap: undefined,
      tax: undefined,
      title_fee: undefined,
      document_fee: undefined,
      registration_fee: undefined,
      down_payment: undefined,
      net_trade: undefined,
      vehicle_cost: undefined,
      credit_score: 550,
      term: 0,
    };
  }

  static get statsValues(): Stats.Input {
    return {
      ...DefaultInput.values,
      term: 0,
      monthly_payment: 0,
      zip: "",
    };
  }
}

export class DefaultInputText {
  static get values(): Model.InputText {
    return {
      applicationId: "",
      vin: "",
      mileage: "",
      monthly_income: "",
      apr: "",
      list_price: "",
      vsc: "",
      gap: "",
      tax: "",
      title_fee: "",
      registration_fee: "",
      document_fee: "",
      down_payment: "",
      net_trade: "",
      vehicle_cost: "",
      credit_score: "",
      term: "",
    };
  }
}

export class DefaultOutput {
  static get values(): Model.Output {
    return {
      vehicle_name: "",
      trim: "",
      current_ltv: 0,
      recommended_ltv: 0,
      down_payment: 0,
      recommended_term: 0,
      recommended_payment: 0,
      recommended_breakeven_month_without_agora_capital: 0,
      recommended_breakeven_month_with_agora_capital: 0,
      agora_collateral_value: 0,
      current_financed_amount: 0,
      recommended_adjustment: 0,
      recommended_financed_amount: 0,
      debt_to_income: 0,
      error_message: "",
      detail: "",
      possible_term_and_payments: [],
      max_ltv: 1,
    };
  }

  static readonly DefaultRule = class {
    static get values(): Stats.Rule {
      return {
        type: RuleType.NONE,
        result: DecisionResult.NONE,
        message: "",
        eligible_min: 0,
        eligible_max: 0,
      };
    }
  };

  static get statsValues(): Stats.Output {
    return {
      stats: getDefaultStatsOutput(),
      decision_rules: [],
      program: getDefaultProgram(),
    };
  }
}

export function getDefaultStatsOutput() {
  const defaultStatsOutput: Stats.Stats = {
    advance_cash: 0,
    expected_cash: 0,
    expected_residual: 0,
    potential_cash: 0,
    potential_residual: 0,
    expected_residual_from_linear_regression: 0,
    expected_cash_from_linear_regression: 0,
    advance_rate: AdvanceRate.SEVENTY_FIVE,
  };

  return defaultStatsOutput;
}

export class DefaultErrors {
  static get values(): Errors.FormErrors {
    return {
      applicationId: "",
      vin: "",
      list_price: false,
      mileage: false,
      apr: false,
      down_payment: false,
      net_trade: false,
      tax: false,
      title_fee: false,
      doc: false,
      reg: false,
      gap: false,
      vsc: false,
      additional_products: false,
      monthly_income: false,
      vehicle_cost: false,
      credit_score: false,
      errorCount: 0,
    };
  }
}

export function getDefaultDecisionRules() {
  const defaultDecisionRules: IDecisionRules = {
    down_payment: DefaultOutput.DefaultRule.values,
    vsc: DefaultOutput.DefaultRule.values,
    gap: DefaultOutput.DefaultRule.values,
    apr: DefaultOutput.DefaultRule.values,
    mileage: DefaultOutput.DefaultRule.values,
    ltv: DefaultOutput.DefaultRule.values,
    payment_to_income: DefaultOutput.DefaultRule.values,
    term: DefaultOutput.DefaultRule.values,
    age_of_vehicle: DefaultOutput.DefaultRule.values,
    credit_score: DefaultOutput.DefaultRule.values,
  };

  return defaultDecisionRules;
}

export function getDefaultErrors() {
  const defaultErrors: Errors.FormErrors = {
    applicationId: "",
    vin: "",
    list_price: false,
    mileage: false,
    apr: false,
    down_payment: false,
    net_trade: false,
    tax: false,
    title_fee: false,
    doc: false,
    reg: false,
    gap: false,
    vsc: false,
    monthly_income: false,
    vehicle_cost: false,
    credit_score: false,
    errorCount: 0,
  };

  return defaultErrors;
}

export function getDefaultProgram() {
  const defaultProgram: IProgram = {
    program_name: "",
    is_eligible: "",
    age_of_vehicle: {
      min: 0,
      max: 0,
      preferred: 0,
    },
    payment_to_income: {
      max: 0,
    },
    mileage: {
      max: 0,
      preferred: 0,
    },
    service_contracts: {
      max: 0,
    },
    term: {
      first_miles_level: 0,
      second_miles_level: 0,
      third_miles_level: 0,
      first_term_level: 0,
      second_term_level: 0,
      third_term_level: 0,
    },
    gap_premium: {
      max: 0,
    },
    down_payment_suggested: {
      min: 0,
      percentage_of_amount_financed: 0,
    },
    apr: {
      min: 0,
    },
    ltv: {
      first_amount_financed_level: 0,
      second_amount_financed_level: 0,
      first_ltv_max: 0,
      second_ltv_max: 0,
    },
  };

  return defaultProgram;
}

export function getDefaultModelInput(state: string | null) {
  const input: Model.FormInput = DefaultInput.values;
  input.state = state;

  return input;
}

export function getDefaultStatsInput(zipCode: string | null, state: string | null) {
  const input: Stats.Input = DefaultInput.statsValues;
  input.zip = zipCode;
  input.state = state;

  return input;
}

export function getDefiApplicationAPIInput() {
  const defaultApplicationAPIInput: Model.DefiApplicationAPIInput = {
    dealer_uuid: "",
    is_agora_user: false,
  };

  return defaultApplicationAPIInput;
}

export function getDefiApplicationAPIOutput() {
  const defaultApplicationAPIOutput: Model.DefiApplicationAPIOutput = {
    app_id: 0,
    app_status: "",
    status_date: "",
    app_source: "",
    app_create_date: "",
    gross_income: 0,
    bureau_score: null,
    custom_score: 0,
    buyer_first_name: "",
    buyer_last_name: "",
    term: 0,
    apr: 0,
    sell_price: 0,
    net_trade: 0,
    down_pmt: 0,
    title_fee: 0,
    doc_fee: 0,
    reg_fee: 0,
    gap_amt: 0,
    vin: "",
    odometer: 0,
    dealer_uuid: "",
    sales_tax: 0,
    service_contract: 0,
    advance_amount: 0,
    advance_rate: 0,
  };

  return defaultApplicationAPIOutput;
}

export function getDefiApplicationUpdateAPIInput() {
  const defaultApplicationAPIOutput: Model.DefiApplicationUpdateAPIInput = {
    app_id: 0,
    gross_income: 0,
    selling_price: 0,
    sales_tax: 0,
    cash_down_payment: 0,
    net_trade: 0,
    title_fee: 0,
    registration_fee: 0,
    doc_fee: 0,
    service_contract: 0,
    gap_amount: 0,
    term: 0,
    apr: 0,
    vin: "",
    odometer: "",
  };

  return defaultApplicationAPIOutput;
}
