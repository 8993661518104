const api_base = "/api";

const orion_path = api_base + "/orion";
export const ORION_DESKING_MODEL = orion_path + "/desking-model";
export const ORION_DESKING_MODEL_STATS = orion_path + "/desking-model-stats";
export const ORION_DESKING_MODEL_STIPULATIONS = orion_path + "/desking-model-stipulations";
export const ORION_VINS = orion_path + "/vins";
export const ORION_BULK_VEHICLE_DETAILS = orion_path + "/bulk-vehicle-details";
export const ORION_VEHICLE_IMAGE = orion_path + "/vehicle-image";
export const ORION_COLLATERAL_VALUATION = orion_path + "/collateral-valuation";

export const VIN_OCR = api_base + "/vin-ocr";
export const FORM_PROCESSING = api_base + "/form-processing";
