import { APIResponse } from "../models/APIResponse";
import { callAPI } from "./APICaller";

// Making a call from the Next client side to the Next Server side
export async function callServerSideAPI(
  apiPath: string,
  requestOptions: any = {},
  propagateError: boolean = false
): Promise<APIResponse> {
  return callAPI(apiPath, requestOptions, undefined, propagateError);
}
