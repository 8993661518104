import * as Sentry from "@sentry/nextjs";
import { useQuery } from "@tanstack/react-query";

// Fetch roles from the API
const fetchRoles = async () => {
  const response = await fetch("/api/fetch-roles");

  if (!response.ok) {
    throw new Error("Failed to fetch roles");
  }

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const useFetchRoles = () => {
  return useQuery({
    queryKey: ["roles"],
    queryFn: fetchRoles,
    staleTime: 1000 * 60 * 10, // Cache data for 10 minutes
    retry: 0, // Retry up to 2 times if there's an error
    refetchOnWindowFocus: false, // Disable refetching when window regains focus
  });
};
