import axios from "axios";
import { NextRequest, NextResponse } from "next/server";

import { VinOcrParamsSchema, vinQueryEnvsSchema } from "../zod-schemas/vinQuerySchema";
import { ReturnValue, FormatType } from "@enums";
import { APIReturnCode } from "@/ctx-api/enums/APIReturnCode";

export async function getVinDetails(req: NextRequest): Promise<NextResponse> {
  const formData = await req.formData();
  const { VINQUERY_API_URL, VINQUERY_API_ACCESS_CODE } = vinQueryEnvsSchema.parse({
    VINQUERY_API_URL: process.env.VIN_QUERY_API_URL,
    VINQUERY_API_ACCESS_CODE: process.env.VIN_QUERY_API_KEY,
  });

  const validatedParams = VinOcrParamsSchema.parse({
    accesscode: VINQUERY_API_ACCESS_CODE,
    saveimage: ReturnValue.FALSE,
    vindecode: ReturnValue.TRUE,
    format: FormatType.JSON,
  });

  const queryString = new URLSearchParams(validatedParams as unknown as Record<string, string>).toString();

  const vinQueryAPIClient = axios.create({
    baseURL: VINQUERY_API_URL,
  });

  const response = await vinQueryAPIClient.post(`?${queryString}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return new NextResponse(JSON.stringify(response.data), { status: APIReturnCode.OK });
}
