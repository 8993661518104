export enum Tier1 {
  FICO = 550,
  LTV = 145,
  PTI = 18,
}

export enum Tier2 {
  FICO = 510,
  LTV = 135,
  PTI = 15,
}

export enum Tier3 {
  FICO = 475,
  LTV = 120,
  PTI = 12,
}

export enum NoFico {
  FICO = "--",
  LTV = 135,
  PTI = 15,
}
